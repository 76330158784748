/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; width:100%; overflow: hidden;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
app-root{
    height: 100%; width:100%; 
}

.highlight{ 
    color:chocolate;
}

.mat-slider{
    height: 75%;
}

.mat-table{
    width: 100%;
}


.mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.25em 0 0.5em 0;
}